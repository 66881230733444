@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Black"), local("HankenGrotesk-Black"),
    url("./HankenGrotesk-Black.woff2") format("woff2"),
    url("./HankenGrotesk-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Medium Italic"),
    local("HankenGrotesk-MediumItalic"),
    url("./HankenGrotesk-MediumItalic.woff2") format("woff2"),
    url("./HankenGrotesk-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk SemiBold Italic"),
    local("HankenGrotesk-SemiBoldItalic"),
    url("./HankenGrotesk-SemiBoldItalic.woff2") format("woff2"),
    url("./HankenGrotesk-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk SemiBold"), local("HankenGrotesk-SemiBold"),
    url("./HankenGrotesk-SemiBold.woff2") format("woff2"),
    url("./HankenGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Bold"), local("HankenGrotesk-Bold"),
    url("./HankenGrotesk-Bold.woff2") format("woff2"),
    url("./HankenGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Regular"), local("HankenGrotesk-Regular"),
    url("./HankenGrotesk-Regular.woff2") format("woff2"),
    url("./HankenGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Thin Italic"), local("HankenGrotesk-ThinItalic"),
    url("./HankenGrotesk-ThinItalic.woff2") format("woff2"),
    url("./HankenGrotesk-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk ExtraBold Italic"),
    local("HankenGrotesk-ExtraBoldItalic"),
    url("./HankenGrotesk-ExtraBoldItalic.woff2") format("woff2"),
    url("./HankenGrotesk-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Medium"), local("HankenGrotesk-Medium"),
    url("./HankenGrotesk-Medium.woff2") format("woff2"),
    url("./HankenGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk ExtraLight Italic"),
    local("HankenGrotesk-ExtraLightItalic"),
    url("./HankenGrotesk-ExtraLightItalic.woff2") format("woff2"),
    url("./HankenGrotesk-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Black Italic"), local("HankenGrotesk-BlackItalic"),
    url("./HankenGrotesk-BlackItalic.woff2") format("woff2"),
    url("./HankenGrotesk-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Light Italic"), local("HankenGrotesk-LightItalic"),
    url("./HankenGrotesk-LightItalic.woff2") format("woff2"),
    url("./HankenGrotesk-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk ExtraBold"), local("HankenGrotesk-ExtraBold"),
    url("./HankenGrotesk-ExtraBold.woff2") format("woff2"),
    url("./HankenGrotesk-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Bold Italic"), local("HankenGrotesk-BoldItalic"),
    url("./HankenGrotesk-BoldItalic.woff2") format("woff2"),
    url("./HankenGrotesk-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Thin"), local("HankenGrotesk-Thin"),
    url("./HankenGrotesk-Thin.woff2") format("woff2"),
    url("./HankenGrotesk-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk ExtraLight"), local("HankenGrotesk-ExtraLight"),
    url("./HankenGrotesk-ExtraLight.woff2") format("woff2"),
    url("./HankenGrotesk-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Italic"), local("HankenGrotesk-Italic"),
    url("./HankenGrotesk-Italic.woff2") format("woff2"),
    url("./HankenGrotesk-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Hanken Grotesk";
  src: local("Hanken Grotesk Light"), local("HankenGrotesk-Light"),
    url("./HankenGrotesk-Light.woff2") format("woff2"),
    url("./HankenGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
