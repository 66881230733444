@import "./_variables";
@import "./_mixins";

// .content--wrapper {
//   // display: flexbox;
//   display: flex;
//   // flex-wrap: nowrap;
//   // width: 100%;
//   // overflow: clip;
//   flex-direction: column;

//   // grid-template-columns: 1fr 1fr;
//   [class*=content--full] {
//     display: flex;
//     width: 100%;
//     flex: 1 0 100%;
//   }

//   .content--full--d {
//     @include for-desktop {
//       width: 100%;
//     }

//     @include for-mobile {
//       width: 50%;
//       flex: 0 1 auto;
//     }
//   }

//   .content--full--m {
//     @include for-desktop {
//       width: 50%;
//       flex: 0 1 auto;
//     }

//     @include for-mobile {
//       width: 100%;
//     }
//   }

// }




div.content--wrapper {
  display: grid;
  // grid-template-columns: 1fr;

  [class*=content--row] {
    display: grid;
    flex-direction: column;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    position: relative;

    &.content--row--full {
      grid-template-columns: 1fr;
    }

    &.content--row--dfull {

      @include for-desktop {
        grid-template-columns: 1fr;
      }

      @include for-mobile {
        grid-template-columns: 1fr 1fr;
      }
    }

    &.content--row--mfull {
      @include for-desktop {
        grid-template-columns: 1fr 1fr;
      }

      @include for-mobile {
        grid-template-columns: 1fr;
      }
    }

    &.content--row--light {
      background-color: $bg--light;
    }

    &.content--row--dark {
      background-color: $bg--dark;
      color: $c--light;
    }

  }

  //   [class*=half--top] {
  //     display: grid;
  //     grid-row: 1;
  //     grid-template-columns: 1fr 1fr;
  //     position: relative;
  //     flex-direction: column;
  //     grid-auto-flow: row;

  //     &.half--top--full {
  //       grid-template-columns: 1fr;
  //     }

  //     &.half--top--rfull {
  //       grid-template-columns: 1fr 1fr;

  //       @include for-desktop {
  //         grid-template-columns: 1fr;
  //       }
  //     }
  //   }

  //   [class*=half--bottom] {
  //     display: grid;
  //     grid-row: 2;
  //     grid-template-columns: 1fr 1fr;
  //     position: relative;
  //     flex-direction: column;
  //     grid-auto-flow: row;

  //     &.half--bottom--full {
  //       grid-template-columns: 1fr;
  //     }

  //     &.half--bottom--rfull {
  //       grid-template-columns: 1fr 1fr;

  //       @include for-desktop {
  //         grid-template-columns: 1fr;
  //       }
  //     }
  //   }

  [class*=content--col--left] {
    grid-column: 1;
    position: relative;

    &.content--col--left--full {
      grid-column: 1/3;
    }

    &.content--col--left--rfull {
      grid-column: 1/3;

      @include for-desktop {
        grid-column: 1;
      }
    }
  }
}

[class*=content--col--right] {
  grid-column: 2;
  position: relative;

  &.content--col--right--full {
    grid-column: 1/3;
  }

  &.content--col--right--rfull {
    grid-column: 1/3;

    @include for-desktop {
      grid-column: 2;
    }
  }
}