// RESET
@import "./reset.scss";
@import "./_variables";
@import "./_mixins";
@import "./_grid";
@import "./fonts/hankenGrotesk/fontface.css";
// @import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

::-webkit-scrollbar {
  width: 0px;
}

html,
body {
  background-color: $bg--light;
  color: $c--dark;
  // font-family: "lato" !important;
  font-family: 'Hanken Grotesk', sans-serif;
  // font-family: "Poppins", sans-serif !important;
  // font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // font-size: calc(1rem + 0.15vw);
  font-size: calc(0.95rem + 0.15vw);
  font-weight: 300;
  // height: 1000%;
  line-height: calc(0.9rem + 0.5vw);
  // line-height: 0.9rem;

  max-width: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;

  #progress_bar {
    background: rgba(255, 255, 255, 0.5);
    height: 3px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform-origin: 0%;
    z-index: 999;
  }

  section.fullpage {
    position: relative;
    width: 100vw;
  }

  [class*=page--separator] {
    background-color: $c--primary;
    height: 2px;
    margin-block: $spacing--h-sm;
    margin-inline: auto;
    width: min(300px, 50vw);

    @include for-desktop {
      &.page--separator--m {
        display: none;
      }

      &.page--separator--d {
        display: block;
      }
    }

    @include for-mobile {
      &.page--separator--m {
        display: block;
      }

      &.page--separator--d {
        display: none;
      }
    }

  }

  .title--primary {
    color: $c--primary
  }

  .title--secondary {
    color: $c--secondary;
  }

  .hidden {
    display: none;
  }

}

h1 {
  font-size: calc(1.35rem + 0.15vw);
  font-weight: 300;
  margin-bottom: 1rem;
  text-transform: uppercase;
  // color: $c--secondary;
  // opacity: 0.8;
}

h2 {
  font-size: calc(1.25rem + 0.15vw);
  font-weight: 300;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  // opacity: 0.7;
}

h3 {
  font-size: calc(1.1rem + 0.15vw);
  font-weight: 400;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  // opacity: 0.7;
  // color: $c--primary;
}

h4 {
  font-size: calc(0.8rem + 0.15vw);
  font-weight: 400;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  // opacity: 0.7;
  // color: $c--primary;
}

h5 {
  font-size: calc(0.7rem + 0.15vw);
  font-weight: 400;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  // opacity: 0.7;
  // color: $c--primary;
}

b {
  font-weight: 400;
}

.text--light {
  color: $c--light;
}

.text--dark {
  color: $c--dark;
}

.text--primary {
  color: $c--primary;
}

.text--secondary {
  color: $c--secondary;
}

// DEBUG BREAKPOINT
[class*=bp-debug] {

  &,
  &:not(.bp-debug--only) * {
    @media screen and (min-width: $bp--w-sm) {
      &:before {
        color: rgba(white, 0.5);
        content: 'bp--w-sm';
      }

      border-inline: 1px solid rgba(magenta, 0.5) !important;
      color: magenta !important;
    }

    @media screen and (min-width: $bp--w-md) {
      &:before {
        color: rgba(white, 0.5);
        content: 'bp--w-md';
        position: absolute;
      }

      border-inline: 1px solid rgba(peru, 0.5) !important;
      color: peru !important;
    }

    @media screen and (min-width: $bp--w-lg) {
      &:before {
        color: rgba(white, 0.5);
        content: 'bp--w-lg';
        position: absolute;
      }

      border-inline: 1px solid rgba(yellow, 0.5) !important;
      color: yellow !important;
    }

    @media screen and (min-width: $bp--w-xl) {
      &:before {
        color: rgba(white, 0.5);
        content: 'bp--w-xl';
        position: absolute;
      }

      border-inline: 1px solid rgba(blue, 0.5) !important;
      color: blue !important;
    }

    @media screen and (min-width: $bp--w-xxl) {
      &:before {
        color: rgba(white, 0.5);
        content: 'bp--w-xxl';
        position: absolute;
      }

      border-inline: 1px 0px solid rgba(red, 0.5) !important;
      color: red !important;
    }

    @media screen and (min-height: $bp--h-sm) {
      &:after {
        color: rgba(white, 0.5);
        content: 'bp--h-sm';
        margin-top: 1.25rem;
        position: absolute;
      }

      border-block: 1px solid rgba(magenta, 0.5) !important;
      color: magenta !important;
    }

    @media screen and (min-height: $bp--h-md) {
      &:after {
        color: rgba(white, 0.5);
        content: 'bp--h-md';
        margin-top: 1.25rem;
        position: absolute;
      }

      border-block: 1px solid rgba(peru, 0.5) !important;
      color: peru !important;
    }

    @media screen and (min-height: $bp--h-lg) {
      &:after {
        color: rgba(white, 0.5);
        content: 'bp--h-lg';
        margin-top: 1.25rem;
        position: absolute;
      }

      border-block: 1px solid rgba(yellow, 0.5) !important;
      color: yellow !important;
    }

    @media screen and (min-height: $bp--h-xl) {
      &:after {
        color: rgba(white, 0.5);
        content: 'bp--h-xl';
        margin-top: 1.25rem;
        position: absolute;
      }

      border-block: 1px solid rgba(blue, 0.5) !important;
      color: blue !important;
    }

    @media screen and (min-height: $bp--h-xxl) {
      &:after {
        color: rgba(white, 0.5);
        content: 'bp--h-xxl';
        margin-top: 1.25rem;
        position: absolute;
      }

      border-block: 1px solid rgba(red, 0.5) !important;
      color: red !important;
    }
  }
}